

app.controller("merFullformCtrl", function ($timeout, $scope, $log) {
    /* Form validation */
    $scope.frmErrors = false;
    $scope.validateForm = function () {
        if ($scope.merForm.$valid == false)
            $scope.frmErrors = true;
        else
            $scope.frmErrors = false;
    };
});

app.controller("mertFormCtrl", function ($timeout, $scope, $log) {
    $scope.merMobile  = $('input[name=merMobile]').val();
    $scope.maddAddress1 = $('input[name=maddAddress1]').val();
    $scope.maddCity = $('input[name=maddCity]').val();
    $scope.maddState = ($("select[name=maddState] option:selected").text() != 'Choose') ? $("select[name=maddState]" + " option:selected").text() : '';
    $scope.maddZIP = $('input[name=maddZIP]').val();
    $scope.maddLat = $('input[name=maddLat]').val();
    $scope.maddLng = $('input[name=maddLng]').val();
    $scope.maddCountry = $('input[name=maddCountry]').val();

    $scope.options = {
        type: 'geocode',
        //bounds: {SWLat: 49, SWLng: -97, NELat: 50, NELng: -96},
        country: 'us',
        typesEnabled: true,
        boundsEnabled: false,
        componentEnabled: true,
        watchEnter: false
    };

    $scope.$watch("fulladdress", function (Key) {
            if (angular.isObject(Key)) {
                $scope.maddAddress1 = (Key.street_number) ? Key.street_number : '';
                $scope.maddAddress1 += (Key.route) ? Key.route : '';
                $scope.maddCity = (Key.locality) ? Key.locality : '';
                $scope.maddState = (Key.administrative_area_level_1) ? Key.administrative_area_level_1 : '';
                $scope.maddZIP = (Key.postal_code) ? Key.postal_code : '';
                $scope.maddLat = (Key.lati) ? Key.lati : '';
                $scope.maddLng = (Key.long) ? Key.long : '';
                $scope.maddCountry = (Key.country) ? Key.country : '';
            }
        }
    );
});


app.controller("storeFormCtrl", ['$scope', 'Upload', function ($scope, Upload, $log) {
    $scope.shopPhone  = $('input[name=shopPhone]').val();
    $scope.saddAddress1 = $('input[name=saddAddress1]').val();
    $scope.saddCity = $('input[name=saddCity]').val();
    $scope.saddState = ($("select[name=saddState] option:selected").text() != 'Choose') ? $("select[name=saddState]" + " option:selected").text() : '';
    $scope.saddZIP = $('input[name=saddZIP]').val();
    $scope.saddLat = $('input[name=saddLat]').val();
    $scope.saddLng = $('input[name=saddLng]').val();
    $scope.saddCountry = $('input[name=saddCountry]').val();


    $scope.shopWebsite = $('input[name=shopWebsite]').val();
    $scope.shopFacebook = $('input[name=shopFacebook]').val();
    $scope.shopTwitter = $('input[name=shopTwitter]').val();
    $scope.shopPinterest = $('input[name=shopPinterest]').val();
    $scope.shopGplus = $('input[name=shopGplus]').val();

    $scope.options = {
        type: 'geocode',
        //bounds: {SWLat: 49, SWLng: -97, NELat: 50, NELng: -96},
        country: 'us',
        typesEnabled: true,
        boundsEnabled: false,
        componentEnabled: true,
        watchEnter: false
    };

    $scope.$watch("fulladdress", function (Key) {
            if (angular.isObject(Key)) {
                $scope.saddAddress1 = (Key.street_number) ? Key.street_number : '';
                $scope.saddAddress1 += (Key.route) ? Key.route : '';
                $scope.saddCity = (Key.locality) ? Key.locality : '';
                $scope.saddState = (Key.administrative_area_level_1) ? Key.administrative_area_level_1 : '';
                $scope.saddZIP = (Key.postal_code) ? Key.postal_code : '';
                $scope.saddLat = (Key.lati) ? Key.lati : '';
                $scope.saddLng = (Key.long) ? Key.long : '';
                $scope.saddCountry = (Key.country) ? Key.country : '';
            }
        }
    );

    /* *************** AUto Check when merchant/store address are same ********* */
    var mZip = $('input[name=maddZIP]').val();
    var mLat = $('input[name=maddLat]').val();
    var mlon = $('input[name=maddLng]').val(); //console.log(mZip+'----'+ mLat +'===='+ mlon);
    if ((mZip && mLat && mlon) && (mZip == $scope.saddZIP && mLat == $scope.saddLat && mlon == $scope.saddLng)) {
        $scope.chkCopyAddress = true;
        calldoCopyAddress($scope);
    }
    /* *************** AUto Check when merchant/store address are same ********* */

    $scope.doCopyAddress = function () {
        calldoCopyAddress($scope);
    };


    //====================== CROP Variables =========================//
    $scope.myImage = '';
    $scope.myCroppedImage = '';
    $scope.myCroppedImageMer = '';

    //====================== CROP 1 ==================================//
    /* Call Crop Image after file  validation */
    $scope.openToggle_mer = function (fileData, event) {
        if(!fileData) {
            $scope.myImage = '';
            $scope.myCroppedImageMer = '';
            $scope.merLogo = '';
        }

        if ($scope.merLogo!='' &&  $scope.merForm.merLogo.$valid) {
            $('#imgcrop_mer').modal('show');
            call_CropImg($scope, event);
        } else {
            $('#imgcrop_mer').modal('hide');
        }
    };
    /* Cancel crop */
    $scope.cancelCrop_mer = function () {
        $('#imgcrop_mer').modal('hide');
        $scope.myImage = '';
        $scope.myCroppedImageMer = '';
        $scope.merLogo = '';
    };
    /* OK crop */
    $scope.okCrop_mer = function () {
        $('#imgcrop_mer').modal('hide');
        //$scope.merLogo = $scope.myCroppedImage;
        $('#merImage').val($scope.myCroppedImageMer);
    };
    //====================== CROP 1 ==================================//

    //====================== CROP 2 ==================================//

    /* Call Crop Image after file  validation */
    $scope.openToggle = function (fileData, event) {
        if(!fileData) {
            $scope.myImage = '';
            $scope.myCroppedImage = '';
            $scope.storeLogo = '';
        }

        if ($scope.storeLogo!='' &&  $scope.merForm.storeLogo.$valid) {
            $('#imgcrop').modal('show');
            call_CropImg($scope, event);
        } else {
            $('#imgcrop').modal('hide');
        }
    };
    /* Cancel crop */
    $scope.cancelCrop = function () {
        $('#imgcrop').modal('hide');
        $scope.myImage = '';
        $scope.myCroppedImage = '';
        $scope.storeLogo = '';
    };
    /* OK crop */
    $scope.okCrop = function () {
        $('#imgcrop').modal('hide');
        //$scope.storeLogo = $scope.myCroppedImage;
        $('#shopImage').val($scope.myCroppedImage);
    };
    //====================== CROP 2 ==================================//

}]);


app.controller('customerListCtrl', function ($http, $scope) {

    /* ************** Pagination ************ */
    $scope.itemsPerPage = rowPerPageCOunt;
    $scope.maxSize = pageNumberMaxSize;

    /* ************** Load data ************ */
    $scope.filteredCustomers = [];
    $scope.loadCustomers = function () {
        $http.get(baseUrlAdmin + 'admin/customer/list-json').then(function (response) {  //console.log(response);
            if (response.status == 200)
                $scope.filteredCustomers = response.data;
            $scope.fLocation = $scope.filteredCustomers[0].caddCity;
        });
    };

    /* ****** blockUnblock starts ************* */
    $scope.blockUnblock = function ($id, $type) {
        call_blockUnblock($id, $type, $http, 'customer');
    };

    $scope.exportToExcel = function (tableId) {
        exportable(tableId);
    };

});

app.controller('dealListCtrl', function ($http, $scope) {

    /* ************** Pagination ************ */
    $scope.itemsPerPage = rowPerPageCOunt;
    $scope.maxSize = pageNumberMaxSize;

    /* ************** Load data ************ */
    $scope.filteredDeals = [];
    $scope.loadDeals = function (key) {
        $http.get(baseUrlAdmin + 'admin/deal/list-json?type='+key).then(function (response) {  //console.log(response);
            if (response.status == 200)
                $scope.filteredDeals = response.data;
        });
    };

    /* ****** blockUnblock starts ************* */
    $scope.blockUnblock = function ($id, $type) {
        call_blockUnblock($id, $type, $http, 'deal');
    };
});

app.controller('couponListCtrl', function ($http, $scope) {

    /* ************** Pagination ************ */
    $scope.itemsPerPage = rowPerPageCOunt;
    $scope.maxSize = pageNumberMaxSize;

    /* ************** Load data ************ */
    $scope.filteredCoupons = [];
    $scope.loadCoupons = function (key) {
        $http.get(baseUrlAdmin + 'admin/coupon/list-json?type='+key).then(function (response) {  //console.log(response);
            if (response.status == 200)
                $scope.filteredCoupons = response.data;
        });
    };

    /* ****** blockUnblock starts ************* */
    $scope.blockUnblock = function ($id, $type) {
        call_blockUnblock($id, $type, $http, 'coupon');
    };
});

app.controller('merchantListCtrl', function ($http, $scope, $modal, Excel, $timeout) {

    /* ************** Pagination ************ */
    $scope.itemsPerPage = rowPerPageCOunt;
    $scope.maxSize = pageNumberMaxSize;

    /* ************** Load data ************ */
    $scope.filteredMerchants = [];
    $scope.loadMerchants = function () {
        $http.get(baseUrlAdmin + 'admin/merchant/list-json/all').then(function (response) {  //console.log(response);
            if (response.status == 200)
                $scope.filteredMerchants = response.data;
        });
    };

    $scope.filteredDeActiveMerchants = [];
    $scope.loadDeActiveMerchants = function () {
        $http.get(baseUrlAdmin + 'admin/merchant/list-json/deactive').then(function (response) {  //console.log(response);
            if (response.status == 200)
                $scope.filteredDeActiveMerchants = response.data;
        });
    };

    $scope.setMerchantId = function (mid) { //add-shop
        var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'shopForm.html',
            controller: 'shopCreateCtrl',
            size: 'lg',
            resolve: {
                shopMerchantId: function () {
                    return mid;
                }
            }
        });
        modalInstance.result.then(function (message) {
            $scope.alerts = message;
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };

    /* ****** blockUnblock starts ************* */
    $scope.blockUnblock = function ($id, $type) {
        call_blockUnblock($id, $type, $http, 'merchant');
    };

    /* ****** subscription stop/start  ************* */
    $scope.subscriptionPayment = function ($mid, $type) {
        call_subscription_payment($mid, $type, $http);
    };

    $scope.exportToExcel = function (tableId) {
        exportable(tableId);
    };

});


app.controller('categoryListCtrl', function ($scope, $http) {

    /* ************** Pagination Starts ************ */
    $scope.itemsPerPage = rowPerPageCOunt;
    $scope.maxSize = pageNumberMaxSize;

    /* ************** Load data ************ */
    $scope.filteredList = [];
    $scope.load = function () {
        $http.get(baseUrlAdmin + 'admin/category/list-json').then(function (response) {  //console.log(response);
            if (response.status == 200)
                $scope.filteredList = response.data;
        });
    };
    $scope.load();

    /* ****** blockUnblock starts ************* */
    $scope.blockUnblock = function ($id, $type) {
        call_blockUnblock($id, $type, $http, 'category');
    };
});




app.controller('shopCreateCtrl', ['$scope', 'Upload', '$modalInstance', 'shopMerchantId', function ($scope, Upload, $modalInstance, shopMerchantId) {
    $scope.sform = {};
    $scope.sform.imgRequired = 'true';
    if (shopMerchantId) {
        $scope.sform.shopMerchantId = shopMerchantId;
    }

    $scope.options = {
        type: 'geocode',
        //bounds: {SWLat: 49, SWLng: -97, NELat: 50, NELng: -96},
        country: 'us',
        typesEnabled: true,
        boundsEnabled: false,
        componentEnabled: true,
        watchEnter: false
    };
    $scope.$watch("fulladdress", function (Key) {
            if (angular.isObject(Key)) {
                $scope.sform.saddAddress1 = (Key.street_number) ? Key.street_number : '';
                $scope.sform.saddAddress1 += (Key.route) ? Key.route : '';
                $scope.sform.saddCity = (Key.locality) ? Key.locality : '';
                $scope.sform.saddState = (Key.administrative_area_level_1) ? Key.administrative_area_level_1 : '';
                $scope.sform.saddZIP = (Key.postal_code) ? Key.postal_code : '';
                $scope.sform.saddLat = (Key.lati) ? Key.lati : '';
                $scope.sform.saddLng = (Key.long) ? Key.long : '';
                $scope.sform.saddCountry = (Key.country) ? Key.country : '';
            }
        }
    );


    //====================== CROP ==================================//
    /* Crop Variables */
    $scope.myImage = '';
    $scope.myCroppedImage = '';

    /* Call Crop Image after file  validation */
    $scope.openToggle = function (fileData, event) {
        if(!fileData) {
            $scope.myImage = '';
            $scope.myCroppedImage = '';
            $scope.file = '';
        }

        if ($scope.file!='' &&  $scope.shopForm.file.$valid) {
            $('#imgcrop').modal('show');
            call_CropImg($scope, event);
        } else {
            $('#imgcrop').modal('hide');
        }
    };
    /* Cancel crop */
    $scope.cancelCrop = function () {
        $('#imgcrop').modal('hide');
        $scope.myImage = '';
        $scope.myCroppedImage = '';
        $scope.file = '';
    };
    /* OK crop */
    $scope.okCrop = function () {
        $('#imgcrop').modal('hide');
        $scope.file = $scope.myCroppedImage;
    };
    //====================== CROP ==================================//

    /* Form validation */
    $scope.frmErrors = false;
    // upload later on form submit or something similar
    $scope.frmSubmit = function () {
        $scope.sform.shopName = $('#shopName').val();
        if ($scope.shopForm.$valid && $scope.shopForm.file.$valid) {
            $scope.frmErrors = true;
            var file = $scope.file;
            var file1 = $scope.file1;
            Upload.upload({
                url: baseUrlAdmin + 'admin/shop/store',
                data: {
                    file: file,
                    file1: file1,
                    'formdata': $scope.sform,
                    _token: $('meta[name="csrf-token"]').attr('content')
                }
            }).then(function (resp) {
                setTimeout(function () {
                    location.reload();
                }, 300);
            }, function (resp) {
                //console.log('Error status: ' + resp.status);
            }, function (evt) {
                $scope.progress =
                    Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }
        else
            $scope.frmErrors = false;
    };

    // alert msg close
    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };

    $scope.closeModel = function () {
        //$("#shopForm").trigger('reset');
        $('#add_branch').modal('hide');
        location.reload();
    };

}]);

app.controller('shopListCtrl', ['$scope', 'Upload', '$http', '$modal', function ($scope, Upload, $http, $modal) {
    $scope.sform = {};
    $scope.getShopInfo = function (shopId) {
        if (shopId) { //alert(shopId);
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'shopForm.html',
                controller: 'shopEditCtrl',
                size: 'lg',
                resolve: {
                    shopId: function () {
                        return shopId;
                    }
                }
            });
            modalInstance.result.then(function (message) {
            }, function () {
                $log.info('Modal dismissed at: ' + new Date());
            });
        }
    };

    /* ****** blockUnblock starts ************* */
    $scope.blockUnblock = function ($id, $type) {
        call_blockUnblock($id, $type, $http, 'shop');
    };

    $scope.setMerchantId = function (mid) { //add-shop
        var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'shopForm.html',
            controller: 'shopCreateCtrl',
            size: 'lg',
            resolve: {
                shopMerchantId: function () {
                    return mid;
                }
            }
        });
        modalInstance.result.then(function (message) {
            $scope.alerts = message;
        }, function () {
            $log.info('Modal dismissed at: ' + new Date());
        });
    };
}]);


app.controller('shopEditCtrl', ['$scope', 'Upload', '$http', '$modalInstance', 'shopId', function ($scope, Upload, $http, $modalInstance, shopId) {
    if (shopId) {
        $scope.sform = {};
        $http.get(baseUrlAdmin + 'admin/shop/edit-json/' + shopId).
            then(function (response) {
                if (response.status == 200) {
                    $(response.data).each(function (i, val) {
                        $('.modal-title').html('Edit Shop');
                        $scope.sform.shopId = shopId;

                        $scope.sform.shopMerchantId = val.shopMerchantId;
                        $scope.sform.shopNumber = val.shopNumber;
                        $scope.sform.shopName = val.shopName;
                        $scope.sform.shopCatId = val.shopCatId;
                        $scope.sform.shopPhone = val.shopPhone;
                        $scope.sform.shopDesc = val.shopDesc;
                        $scope.sform.shopWebsite = val.shopWebsite;

                        $scope.sform.saddAddress1 = val.saddAddress1;
                        $scope.sform.saddCity = val.saddCity;
                        $scope.sform.saddState = val.saddState;
                        $scope.sform.saddZIP = val.saddZIP;
                        $scope.sform.saddLat = val.saddLat;
                        $scope.sform.saddLng = val.saddLng;
                        $scope.sform.saddCountry = val.saddCountry;

                        $scope.sform.shopFacebook = val.shopFacebook;
                        $scope.sform.shopTwitter = val.shopTwitter;
                        $scope.sform.shopPinterest = val.shopPinterest;
                        $scope.sform.shopGplus = val.shopGplus;

                        $scope.bannerSrc = baseUrlAdmin + 'gallery/store/banner/' + val.shopId + '.jpg';
                        $scope.logoSrc = baseUrlAdmin + 'gallery/store/logo/' + val.shopId + '.jpg';
                        $scope.sform.imgRequired = 'false';

                    });
                }
            });
    }

    $scope.options = {
        type: 'geocode',
        //bounds: {SWLat: 49, SWLng: -97, NELat: 50, NELng: -96},
        country: 'us',
        typesEnabled: true,
        boundsEnabled: false,
        componentEnabled: true,
        watchEnter: false
    };
    $scope.$watch("fulladdress", function (Key) {
            if (angular.isObject(Key)) {
                $scope.sform.saddAddress1 = (Key.street_number) ? Key.street_number : '';
                $scope.sform.saddAddress1 += (Key.route) ? Key.route : '';
                $scope.sform.saddCity = (Key.locality) ? Key.locality : '';
                $scope.sform.saddState = (Key.administrative_area_level_1) ? Key.administrative_area_level_1 : '';
                $scope.sform.saddZIP = (Key.postal_code) ? Key.postal_code : '';
                $scope.sform.saddLat = (Key.lati) ? Key.lati : '';
                $scope.sform.saddLng = (Key.long) ? Key.long : '';
                $scope.sform.saddCountry = (Key.country) ? Key.country : '';
            }
        }
    );

    $scope.disableLogoDbImg = function () {
        $('#logoDbImg').css('visibility', 'hidden');
    };
    $scope.disableBannerDbImg = function () {
        $('#bannerDbImg').css('visibility', 'hidden');
    };


    //====================== CROP ==================================//
    /* Crop Variables */
    $scope.myImage = '';
    $scope.myCroppedImage = '';

    /* Call Crop Image after file  validation */
    $scope.openToggle = function (fileData, event) {
        if(!fileData) {
            $scope.myImage = '';
            $scope.myCroppedImage = '';
            $scope.file = '';
        }
        if ($scope.file!='' && $scope.shopForm.file.$valid) {
            $('#imgcrop').modal('show');
            call_CropImg($scope, event);
        } else {
            $('#imgcrop').modal('hide');
        }
    };
    /* Cancel crop */
    $scope.cancelCrop = function () {
        $('#imgcrop').modal('hide');
        $scope.myImage = '';
        $scope.myCroppedImage = '';
        $scope.file = '';
    };
    /* OK crop */
    $scope.okCrop = function () {
        $('#imgcrop').modal('hide');
        $scope.file = $scope.myCroppedImage;
    };
    //====================== CROP ==================================//

    /* Form validation */
    $scope.frmErrors = false;
    $scope.frmSubmit = function () {
        $scope.sform.shopName = $('#shopName').val();
        if ($scope.shopForm.$valid && $scope.sform.shopId) {
            $scope.frmErrors = true; // && $scope.shopForm.file.$valid
            var file = $scope.file;
            var file1 = $scope.file1;
            Upload.upload({
                url: baseUrlAdmin + 'admin/shop/update',
                data: {
                    file: file,
                    file1: file1,
                    'formdata': $scope.sform,
                    _token: $('meta[name="csrf-token"]').attr('content')
                }
            }).then(function (resp) {
                setTimeout(function () {
                    location.reload();
                }, 300);
                //$modalInstance.close([{type: 'success', msg: 'Details updated successfully'}]);
            }, function (resp) {
                //console.log('Error status: ' + resp.status);
                $scope.alerts = [{type: 'danger', msg: 'Oops! Error.', show: true}];
                //$modalInstance.close([{type: 'danger', msg: 'Oops! Error.'}]);
            }, function (evt) {
                $scope.progress =
                    Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        } else
            $scope.frmErrors = false;
    };

    // alert msg close
    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };

    $scope.closeModel = function () {
        //$("#shopForm").trigger('reset');
        $('#add_branch').modal('hide');
        location.reload();
    };

}]);


app.controller('imgLibraryListCtrl', function ($http, $scope) {

    /* ************** Pagination Starts ************ */
    $scope.currentPage = 1;
    $scope.itemsPerPage = rowPerPageCOunt;
    $scope.maxSize = pageNumberMaxSize;
    /* ************** Pagination Ends ************ */

    $scope.filteredImages = [];
    $scope.loadImages = function () {
        $http.get(baseUrlAdmin + 'admin/offer-image/list-json').then(function (response) {  //console.log(response);
            if (response.status == 200)
                $scope.filteredImages = response.data;
        });
    };


    /*
     // Check / Uncheck All
     $scope.checkAll = function() {
     for(var i=0; i < $scope.filteredImages.length; i++) {
     $scope.filteredImages[i].selected = $scope.allChecked;
     }
     };
     //uncheck 'check all' when any one of chkbox is unchecked
     $scope.changeCheckAll = function() {
     for(var i = 0; i < $scope.filteredImages.length; i++) {
     if (!$scope.filteredImages[i].selected) {
     $scope.allChecked = false;
     return false;
     }
     }
     $scope.allChecked = true;
     };

     $scope.deleteAll = function () {
     var chkdItem = [];
     angular.forEach($scope.filteredImages, function(item) {
     if ( angular.isDefined(item.selected) && item.selected === true ) {
     chkdItem.push(item.imgId);
     }
     });
     if(chkdItem.length>0){
     call_multiDelete(chkdItem,$http);
     }
     }; */

});




app.controller('dealEditCtrl', function ($scope,$http) {
    $scope.selectedImageError = false;
    $scope.myInterval = 5000;
    $scope.slides = [];
    var formData = {
        _token: $('meta[name="csrf-token"]').attr('content')
    };
    // update the form value
    $http.post(baseUrlAdmin + 'admin/offer-image/get-images', formData).
        success(function (data, status, headers, config) {
            $scope.slides = data;
        });
    $scope.searchImages = function () {
        $scope.slides = [];
        if (!$scope.searchTerm)
            $scope.showSearchError = true;
        else {
            $scope.slides = [];
            var formData = {
                _token: $('meta[name="csrf-token"]').attr('content'),
                term: $scope.searchTerm
            };
            // update the form value
            $http.post(baseUrlAdmin + 'admin/offer-image/get-images', formData).
                success(function (data, status, headers, config) {
                    $scope.slides = data;
                });
        }
    };
    $scope.selectImage = function (img, id) {
        //$scope.selectedImageContainer = true;
        $scope.selectedImageError = false;
        $scope.selectedImageSrc = img;
        $scope.dealImageId = id;
    };

    /* date format starts */
    $scope.open = function ($event) {
        $scope.status.opened = true;
    };
    $scope.open2 = function ($event) {
        $scope.status.opened2 = true;
    };
    $scope.open3 = function ($event) {
        $scope.status.opened3 = true;
    };
    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };
    $scope.format = 'dd-MMMM-yyyy';
    $scope.status = {
        opened: false,
        opened2: false,
        opened3: false
    };
    /* date format ends */

    $scope.changePrice = function () {
        var ori_price = parseFloat($scope.dealValue).toFixed(2);
        var percentage = $scope.dealDiscount;
        var offer_price = parseFloat(ori_price * (percentage / 100)).toFixed(2);
        offer_price = parseFloat(ori_price - offer_price).toFixed(2);
        $scope.dealPrice = offer_price;
        $scope.dealValue = parseFloat($scope.dealValue).toFixed(2);
    };

    $scope.changeDiscount = function () {
        if ($scope.dealValue) {
            var off = $scope.dealDiscount;
            off = $scope.dealValue * off / 100;
            $scope.dealPrice = parseFloat($scope.dealValue - off).toFixed(2);
        }
    };
    $scope.dealErrors=false;
    $scope.termCount = false;
    $scope.validateDealForm=function(){
        if($scope.dealForm.$valid==false)
            $scope.dealErrors=true;
        else
            $scope.dealErrors=false;
        var count=$('.termCheckboxes').find('input[type=checkbox]:checked').length;
        if(count<1){
            $scope.termCount=true;
            $scope.dealForm.$invalid = true;
        }else{
            $scope.termCount=false;
            if($scope.dealErrors==false)
                $scope.dealForm.$invalid = false;
        }
    };
    $scope.termCheck = function(){
        var count=$('.termCheckboxes').find('input[type=checkbox]:checked').length;
        if(count<1){
            $scope.termCount=true;
        }else{
            $scope.termCount=false;
        }
    };
});


app.controller('couponEditCtrl', function ($scope, $locale, $http, $window) {
    $scope.couDiscountNumber=99;
    $scope.selectedImageError = false;
    $scope.myInterval = 5000;
    $scope.discountMaxLength = 99;
    $scope.slides = [];
    var formData = {
        _token: $('meta[name="csrf-token"]').attr('content')
    };
    // update the form value
    $http.post(baseUrlAdmin + 'admin/offer-image/get-images', formData).
        success(function (data, status, headers, config) {
            $scope.slides = data;
        });
    $scope.searchImages = function () {
        $scope.slides = [];
        if (!$scope.searchTerm)
            $scope.showSearchError = true;
        else {
            $scope.slides = [];
            var formData = {
                _token: $('meta[name="csrf-token"]').attr('content'),
                term: $scope.searchTerm
            };
            // update the form value
            $http.post(baseUrlAdmin + 'admin/offer-image/get-images', formData).
                success(function (data, status, headers, config) {
                    $scope.slides = data;
                });
        }

    };

    $scope.selectImage = function (img, id) {
        //$scope.selectedImageContainer = true;
        $scope.selectedImageError = false;
        $scope.selectedImageSrc = img;
        $scope.couImageId = id;
    };
    $scope.$watch('couNoDiscount', function (newValue, oldValue) {
        if (newValue == true) {
            $scope.couponForm.couOriginalValue.$setValidity('required', true);
            $scope.couponForm.couDiscountDummy.$setValidity('required', true);
            $scope.couponForm.couFinalValue.$setValidity('required', true);
        } else {
            if (isNaN($scope.couOriginalValue))
                $scope.couponForm.couOriginalValue.$setValidity('required', false);
            if (isNaN($scope.couDiscountDummy))
                $scope.couponForm.couDiscountDummy.$setValidity('required', false);
        }
    });

    $scope.checkTerm = function ($id) {
        if ($id == true) {
            $scope.couponForm.couTerms.$setValidity('required', true);
        } else {
            $scope.couponForm.couTerms.$setValidity('required', false);
        }
    };

    /* date format starts */
    $scope.open = function ($event) {
        $scope.status.opened = true;
    };
    $scope.open2 = function ($event) {
        $scope.status.opened2 = true;
    };
    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };
    $scope.format = 'dd-MMMM-yyyy';
    $scope.status = {
        opened: false,
        opened2: false
    };
    /* date format ends */

    $scope.couponErrors=false;
    $scope.termCount = false;
    $scope.validateCouponForm=function(){
        if ($scope.couponForm.$valid == false) {
            $scope.couponErrors = true;
        } else {
            $scope.couponErrors = false;
        }
        var count=$('.termCheckboxes').find('input[type=checkbox]:checked').length;
        if(count<1){
            $scope.termCount=true;
            $scope.couponForm.$invalid = true;
        }else{
            $scope.termCount=false;
            if($scope.couponErrors==false)
                $scope.couponForm.$invalid = false;
        }
    };

    $scope.prvTerms= function($id,$term){
        if($('#'+$id).text()=='')
            $('.prvTerms').append('<li id="'+$id+'">'+$term+'</li>');
        else
            $('#'+$id).remove();
        var count=$('.termCheckboxes').find('input[type=checkbox]:checked').length;
        if(count<1){
            $scope.termCount=true;
        }else{
            $scope.termCount=false;
        }
    };

    $scope.changeSymbol = function ($val) {
        if ($val == 1) {
            var off = $scope.couDiscountDummy;
            $scope.discountMaxLength = 99;
            if ($scope.couOriginalValue && $scope.couDiscountDummy) {
                off = $scope.couOriginalValue * off / 100;
                var price = parseFloat($scope.couOriginalValue - off).toFixed(2);
                if (price > 1) {
                    $scope.couFinalValue = price;
                } else {
                    $scope.couFinalValue = '';
                }
            }
        } else {
            var off = $scope.couDiscountDummy;
            $scope.discountMaxLength = $scope.couOriginalValue;
            if ($scope.couOriginalValue && $scope.couDiscountDummy) {
                var price = parseFloat($scope.couOriginalValue - off).toFixed(2);
                if (price > 1) {
                    $scope.couFinalValue = price;
                } else {
                    $scope.couFinalValue = '';
                }
            }
        }
    };
    $scope.changePrice = function () {
        var sym = $('.symbol_select:checked').val();
        if (sym == 1) {
            var off = $('.discount').val();
            off = $scope.couOriginalValue * off / 100;
            $scope.couFinalValue = parseFloat($scope.couOriginalValue - off).toFixed(2);
            $scope.discountMaxLength = 99;
        }
        else if (sym == 2) {
            var off = $('.discount').val();
            $scope.discountMaxLength = $scope.couOriginalValue;
            var price = parseFloat($scope.couOriginalValue - off).toFixed(2);
            if (price > 1) {
                $scope.couFinalValue = price;
            } else {
                $scope.couFinalValue = '';
            }
        }
        $scope.couOriginalValue = parseFloat($scope.couOriginalValue).toFixed(2);
    };

    $scope.changeDiscount = function () {
        $('.discount_value').val($scope.couDiscountDummy);
        var sym = $('.symbol_select:checked').val();
        if (sym == 1) {
            if ($scope.couOriginalValue) {
                var off = $('.discount').val();
                off = $scope.couOriginalValue * off / 100;
                $scope.couFinalValue = parseFloat($scope.couOriginalValue - off).toFixed(2);
            }
        }
        else if (sym == 2) {
            if ($scope.couOriginalValue) {
                var off = $('.discount').val();
                $scope.couFinalValue = parseFloat($scope.couOriginalValue - off).toFixed(2);
            }
        }
    };
    $scope.noDiscount = function () {  //differ from merchant -- coupon script -- $('.noDiscount').click(function () {
        if ($('.noDiscount').is(':checked')) {
            $('.final_value').removeAttr('readonly');
            $('.offSection').addClass('hide');
        } else {
            $('.final_value').attr('readonly', 'readonly');
            $('.offSection').removeClass('hide');
        }
    };

});


app.controller('adminEditSettingCtrl', function ($http, $scope) {
    $scope.aeform = {};
    $scope.adminEdiSubmit = function () {
        var formData = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            data: $scope.aeform
        };
        $http.post(baseUrlAdmin + 'admin/edit-setting', formData).
            success(function (data) {
                setTimeout(function () {
                    location.reload();
                }, 300);
            });
    };
});

app.controller('adminPwdCtrl', function ($http, $scope) {
    $scope.submit = function () {
        var formData = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            data: $scope.pfrm
        };
        // update the form value
        $http.post(baseUrl + 'admin/change-password', formData).
            success(function (data, status, headers, config) {// 'success', 'danger','warning', 'info'
                if (data.type == 1) {
                    //$modalInstance.close([{type: 'success', msg: data.message}]);
                    $scope.alerts = [{type: 'success', msg: data.message, show: true}];
                }
                else {
                    // $modalInstance.close([{type: 'danger', msg: data.message}]);
                    $scope.alerts = [{type: 'danger', msg: data.message, show: true}];
                }
            }).error(function (data, status) {
                //$modalInstance.close([{type: 'danger', msg: data.message}]);
                $scope.alerts = [{type: 'danger', msg: data.message, show: true}];
            });
    };

    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };
});


app.controller('categoryCtrl', function ($scope) {
    $scope.frm = {};
    $scope.categoryErrors = false;
    $scope.validateCategoryForm = function () {
        if ($scope.categoryForm.$valid == false)
            $scope.categoryErrors = true;
        else
            $scope.categoryErrors = false;
    };
    $scope.selectMe = function (event) {
        $('.material-icons-list li').removeClass('selected');
        $scope.frm.catImage = $(event.currentTarget).find('.material-icons').html(); //event.target.innerHTML;
        $(event.currentTarget).addClass('selected');
    };

});

app.controller('loyaltyCtrl', function ($scope) {
    $scope.Errors = false;
    $scope.validateForm = function () {
        if ($scope.chkForm.$valid == false)
            $scope.Errors = true;
        else
            $scope.Errors = false;
    };

});

app.controller('imgLibraryCreateCtrl', ['$scope', 'Upload', '$http', function ($scope, Upload, $http) {
    $scope.frm = {};

    /* Form validation */
    $scope.frmErrors = false;
    $scope.validateForm = function () {
        if ($scope.imgForm.$valid == false)
            $scope.frmErrors = true;
        else
            $scope.frmErrors = false;
    };

    //====================== CROP ==================================//
    /* Crop Variables */
    $scope.myImage = '';
    $scope.myCroppedImage = '';

    /* Call Crop Image after file  validation */
    $scope.openToggle = function (fileData, event) {
        var flag = 0;
        if(!fileData) {
            $scope.myImage = '';
            $scope.myCroppedImage = '';
            //$scope.frm.image = '';
            flag = 1;
        }
        if (flag==0 && $scope.imgForm.image.$valid) {
            $('#imgcrop').modal('show');
            call_CropImg($scope, event);
        } else {
            $('#imgcrop').modal('hide');
        }
    };
    /* Cancel crop */
    $scope.cancelCrop = function () {
        $('#imgcrop').modal('hide');
        $scope.myImage = '';
        $scope.myCroppedImage = '';
        $scope.frm.image = '';
    };
    /* OK crop */
    $scope.okCrop = function () {
        $('#imgcrop').modal('hide');
        $scope.frm.image = $scope.myCroppedImage;
    };
    //====================== CROP ==================================//

    $scope.disableLogoDbImg = function () {
        $('#logoDbImg').hide();
    };

    /* Ajax Form submit */
    $scope.submit = function () {
        if ($scope.imgForm.$valid && $scope.imgForm.image.$valid) {
            Upload.upload({
                url: baseUrlAdmin + 'admin/offer-image/store',
                data: {'formdata': $scope.frm, _token: $('meta[name="csrf-token"]').attr('content')}

            }).then(function () {
                setTimeout(function () {
                    location.reload();
                }, 300);
            }, function (resp) { //console.log('Error status: ' + resp.status);
            }, function (evt) {
                $scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }
    };

    /* success Message  */
    $scope.closeAlert = function (index) {
        $scope.alerts.splice(index, 1);
    };

    /* Ajax Edit Form submit */
    $scope.editsubmit = function () {
        if ($scope.imgForm.$valid) {
            Upload.upload({
                url: baseUrlAdmin + 'admin/offer-image/update/' + $scope.frm.imgId,
                data: {'formdata': $scope.frm, _token: $('meta[name="csrf-token"]').attr('content')}
            }).then(function (res) {
                setTimeout(function () {
                    location.href = baseUrlAdmin + 'admin/offer-image/list';
                }, 300);
            }, function (resp) { //console.log('Error status: ' + resp.status);
            }, function (evt) {
                $scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }
    };

}]);


app.controller("mapCtrl", function ($scope, NgMap) {

    $scope.placeChanged = function () { //console.log(this.getPlace());
        $scope.place = this.getPlace();  //console.log('location', $scope.place.geometry.location);
        $scope.map.setCenter($scope.place.geometry.location);
    };
    NgMap.getMap().then(function (map) {
        $scope.map = map;
    });

    $scope.applyModel = function () {
        var pickedPlace = $scope.place;
        var lati = pickedPlace.geometry.location.lat();
        var long = pickedPlace.geometry.location.lng();
        var addressComponents = pickedPlace.address_components;
        $scope.$parent.fulladdress = parseGoogleResponse(addressComponents, lati, long);
        $('#map').modal('hide');
    };

    var parseGoogleResponse = function (components, lati, long) {
        var result = {};
        for (var i = 0; i < components.length; i++) {
            var addressType = components[i].types[0];
            if (addressType == 'administrative_area_level_1') {
                result[addressType] = components[i]['short_name'];
            } else {
                result[addressType] = components[i]['long_name'];
            }
        }
        result['lati'] = lati;
        result['long'] = long;
        return result;
    };

    $scope.closeModel = function () {
        $('#map').modal('hide');
    };

});

app.controller('customerFormCtrl', function ($scope) {
    $scope.Errors = false;
    $scope.validateForm = function () {
        if ($scope.cusForm.$valid == false)
            $scope.Errors = true;
        else
            $scope.Errors = false;
    };
});
