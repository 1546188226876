

app.filter('DateToDateFormat', function DateToDateFormat($filter) {
    return function (text) {
        var tempdate = new Date(text.replace(/-/g, "/"));
        return $filter('date')(tempdate, "M/d/yyyy h:mm a");
    }
});

app.filter('zpad', function() {
 return function(input, n) {
  if(input === undefined)
   input = ""
  if(input.length >= n)
   return input
  var zeros = "0".repeat(n);
  return (zeros + input).slice(-1 * n)
 };
});

/*
 app.filter('TimeToDateFormat', function TimeToDateFormat($filter) {
 return function (text) {
 var tempdate = text * 1000;
 return $filter('date')(tempdate, "MM/dd/yyyy h:mm a");
 }
 });
*/

/* app.filter("unique", function () {
 return function (data) {
 if (angular.isArray(data)) {
 var result = [];
 var key = {};
 for (var i = 0; i < data.length; i++) {
 var val = data[i];
 if (angular.isUndefined(key[val])) {
 key[val] = val;
 result.push(val);
 }
 }
 if (result.length > 0) {
 return result;
 }
 }
 return data;
 }
 }); */
