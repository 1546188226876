
/* ******* Add Store - Popup Model - Google Map - autosuggest CSS z-index set  ******* */
$(function () {
    var pacContainerInitialized = false;
    $('#shopModelpopup').on("focus", function () {
        if (!pacContainerInitialized) {
            $('.pac-container').css('z-index', '9999');
            pacContainerInitialized = true;
        }
    });
});
$(function () {
    $("#tabs").tabs();
});

/* ******* Auto close - ajax success/failure message  ******* */
window.setTimeout(function () {
    $(".alert").fadeTo(500, 0).slideUp(500, function () {
        $(this).remove();
    });
}, 2000);


function calldoCopyAddress($scope) {
    if ($scope.chkCopyAddress)   // when checked
    {
        $scope.saddAddress1 = $('input[name=maddAddress1]').val();
        $scope.saddCity = $('input[name=maddCity]').val();
        $scope.saddState = ($("select[name=maddState] option:selected").text() != 'Choose') ? $("select[name=maddState] option:selected").text() : '';
        $scope.saddZIP = $('input[name=maddZIP]').val();
        $scope.saddLat = $('input[name=maddLat]').val();
        $scope.saddLng = $('input[name=maddLng]').val();
        $scope.saddCountry = $('input[name=maddCountry]').val();
    } else {
        $scope.saddAddress1 = '';
        $scope.saddCity = '';
        $scope.saddState = '';
        $scope.saddZIP = '';
        $scope.saddLat = '';
        $scope.saddLng = '';
        $scope.saddCountry = '';
    }
}

function call_subscription_payment($id, $type, $http) {
    var formData = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        data: $id,
        type: $type
    };
    if ($type == 0)
        var msg = 'stop';
    else if ($type == 1)
        var msg = 'start';

    $('.messi').remove();
    new Messi('Are you sure want to ' + msg + ' the payment?', {
        title: 'Payment Subscription',
        autoclose: 10000,
        buttons: [{id: 0, label: 'Yes', val: 'Y'}, {id: 1, label: 'No', val: 'N'}],
        callback: function (val) {
            if (val == 'N') { }
            else {
                $http.post(baseUrlAdmin + 'admin/merchant/subscription', formData).
                    success(function (data, status, headers, config) {
                        setTimeout(function () {
                            location.reload();
                        }, 300);
                    });
            }
        }
        ,modal: true});
}



function exportable(tableId) {
    var blob = new Blob([document.getElementById(tableId).innerHTML], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
    });
    saveAs(blob, tableId+".xls");
}


function call_blockUnblock($id, $type, $http, $page) {
    $pageName = ($page=='cancel-reason')?'reason':$page;
    var formData = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        data: $id,
        type: $type
    };
    if ($type == 1)
        var msg = 'block';
    else if ($type == 2)
        var msg = 'unblock';

    $('.messi').remove();
    new Messi('Are you sure want to ' + msg + ' the ' + $pageName + '?', {
        title: '',
        autoclose: 5000,
        buttons: [{id: 0, label: 'Yes', val: 'Y'}, {id: 1, label: 'No', val: 'N'}],
        callback: function (val) {
            if (val == 'N') {
            }
            else {
                $http.post(baseUrlAdmin + 'admin/' + $page + '/blockUnblock', formData).
                    success(function (data, status, headers, config) {
                        setTimeout(function () {
                            location.reload();
                        }, 300);
                    });
            }
        }
        ,modal: true});
}




/*
 function call_multiDelete(chkdItem,$http) {
 var formData = {
 _token: $('meta[name="csrf-token"]').attr('content'),
 data : chkdItem
 };
 new Messi('Are you sure want to delete?', {
 title: '',
 buttons: [{id: 0, label: 'Yes', val: 'Y'}, {id: 1, label: 'No', val: 'N'}],
 callback: function (val) {
 if (val == 'N') {
 $(".modalOverlay").hide();
 return false;
 } else {
 $http.post(baseUrlAdmin + 'admin/offer-image/delete', formData).
 success(function (data, status, headers, config) {
 setTimeout(function () {
 location.reload();
 }, 300);
 });
 }
 }
 });
 }

 function call_Delete($id,$name,$http) {
 var formData = {
 _token: $('meta[name="csrf-token"]').attr('content'),
 data: $id,
 name: $name
 };
 new Messi('Are you sure want to delete?', {
 title: '',
 buttons: [{id: 0, label: 'Yes', val: 'Y'}, {id: 1, label: 'No', val: 'N'}],
 callback: function (val) {
 if (val == 'N') {
 $(".modalOverlay").hide();
 return false;
 } else {
 $http.post(baseUrlAdmin + 'admin/offer-image/delete', formData).
 success(function (data, status, headers, config) {
 setTimeout(function () {
 location.reload();
 }, 300);
 });
 }
 }
 });
 } */

function call_CropImg($scope, event) {
    var handleFileSelect = function (evt) {
        var file = evt.currentTarget.files[0];
        if(file!=undefined) {
            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function () {
                    $scope.myImage = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        }
    };
    handleFileSelect(event);
}




