
app.directive('ngAutocomplete', function () {
    return {
        require: 'ngModel',
        scope: {
            ngModel: '=',
            options: '=?',
            fulladdress: '=?',
        },
        link: function (scope, element, attrs, controller) {
            //options for autocomplete
            var opts;
            var watchEnter = false;
            //convert options provided to opts
            var initOpts = function () {

                opts = {};
                if (scope.options) {

                    if (scope.options.watchEnter !== true) {
                        watchEnter = false
                    } else {
                        watchEnter = true
                    }

                    if (scope.options.types) {
                        opts.types = [];
                        opts.types.push(scope.options.types);
                        scope.gPlace.setTypes(opts.types)
                    } else {
                        scope.gPlace.setTypes([])
                    }

                    if (scope.options.bounds) {
                        opts.bounds = scope.options.bounds;
                        scope.gPlace.setBounds(opts.bounds)
                    } else {
                        scope.gPlace.setBounds(null)
                    }

                    if (scope.options.country) {
                        opts.componentRestrictions = {
                            country: scope.options.country
                        };
                        scope.gPlace.setComponentRestrictions(opts.componentRestrictions)
                    } else {
                        scope.gPlace.setComponentRestrictions(null)
                    }
                }
            };

            if (scope.gPlace == undefined) {
                scope.gPlace = new google.maps.places.Autocomplete(element[0], {});
            }
            google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
                var result = scope.gPlace.getPlace();
                var lati = result.geometry.location.lat();
                var long = result.geometry.location.lng();

                if (result !== undefined) {
                    if (result.address_components !== undefined) {
                        var addressComponents = result.address_components;
                        //console.log(addressComponents);
                        scope.$apply(function () { //object  key-value conversion
                            scope.fulladdress = parseGoogleResponse(addressComponents, lati, long);
                            // console.log(scope.fulladdress);
                            controller.$setViewValue(element.val());
                        });
                    }
                }
            });

            var parseGoogleResponse = function (components, lati, long) {
                var result = {};
                for (var i = 0; i < components.length; i++) {
                    var addressType = components[i].types[0];
                    if (addressType == 'administrative_area_level_1') {
                        result[addressType] = components[i]['short_name'];
                    } else {
                        result[addressType] = components[i]['long_name'];
                    }
                }
                result['lati'] = lati;
                result['long'] = long;
                return result;
            };

            controller.$render = function () {
                var location = controller.$viewValue;
                element.val(location);
            };

            //watch options provided to directive
            scope.watchOptions = function () {
                return scope.options
            };
            scope.$watch(scope.watchOptions, function () {
                initOpts()
            }, true);

        }
    };
});


app.directive('logoimgonload', function ($http) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            attrs.$observe('ngSrc', function (ngSrc) {
                $http.get(ngSrc).success(function () {
                    element.attr('width', '80px');
                    element.attr('height', '50px');
                }).error(function () {
                });
            });
        }
    };
});

app.directive('bannerimgonload', function ($http) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            attrs.$observe('ngSrc', function (ngSrc) {
                $http.get(ngSrc).success(function () {
                    element.attr('width', '80px');
                    element.attr('height', '50px');
                }).error(function () {
                });
            });
        }
    };
});

/* confirm password compare */
app.directive("compareTo", function () {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=compareTo"
        },
        link: function (scope, element, attributes, ngModel) {

            ngModel.$validators.compareTo = function (modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function () {
                ngModel.$validate();
            });
        }
    };
});


app.directive('uniqueCategory', function ($http, $q) {
    return {
        require: 'ngModel',
        link: function ($scope, element, attrs, ngModel) {
            ngModel.$validators.uniqueCategory = function (modelValue, viewValue) {
                var formData = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    name: modelValue || viewValue,
                    catId: ($('#catId').val() ? $('#catId').val() : '')
                };
                $http.post(baseUrlAdmin + 'admin/category/check-category', formData)
                    .success(function (data, status, headers, config) {   // console.log(data);
                        var defer = $q.defer();
                        ngModel.$setValidity('uniqueCategory', data == 0 ? true : false);
                        defer.resolve;
                        return defer.promise;
                    });
            }
        }
    }
});


app.directive('uniquePhone', function ($timeout, $q, $http) { // console.log($('#usrSourceId').val());
    return {
        restrict: 'AE',
        require: 'ngModel',
        link: function (scope, elm, attr, model) {
            /*model.$validators.validCharacters = function (modelValue, viewValue) {
             return /^[\+]?[(]?[0-9]{3}[)]?[-]?[0-9]{3}[-]?[0-9]{4,4}$/.test(modelValue || viewValue);
             };*/
            //model.$setValidity('phoneExists', false);
            model.$validators.phoneExists = function (modelValue, viewValue) {
                var formData = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    phone: modelValue || viewValue,
                    usrSourceId: $('#usrSourceId').val()
                };
                // update the form value
                $http.post(baseUrlAdmin + 'admin/customer/unique-phone', formData).success(function (data, status, headers, config) {
                    var resp = data == 0 ? true : false;     // console.log('res'+resp);
                    var defer = $q.defer();
                    model.$setValidity('phoneExists', resp);
                    defer.resolve;
                    return defer.promise;
                });
            }
        }
    }
});

app.directive('httpPrefix', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, controller) {
            function ensureHttpPrefix(value) {
                // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
                if (value && !/^(https?):\/\//i.test(value)
                    && 'http://'.indexOf(value) !== 0 && 'https://'.indexOf(value) !== 0) {
                    controller.$setViewValue('http://' + value);
                    controller.$render();
                    return 'http://' + value;
                }
                else
                    return value;
            }

            controller.$formatters.push(ensureHttpPrefix);
            controller.$parsers.splice(0, 0, ensureHttpPrefix);
        }
    };
});

app.directive('uniqueShop', function ($http, $q) {
    return {
        require: 'ngModel',
        link: function ($scope, element, attrs, ngModel) {
            ngModel.$validators.uniqueShop = function (modelValue, viewValue) {
                var formData = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    name: modelValue || viewValue,
                    shopId: $scope.sform.shopId,
                    shopMerchantId: $scope.sform.shopMerchantId
                };
                $http.post(baseUrlAdmin + 'admin/shop/unique-shop', formData)
                    .success(function (data, status, headers, config) {
                        var defer = $q.defer();
                        ngModel.$setValidity('uniqueShop', data == 0 ? true : false);
                        defer.resolve(true);
                        return defer.promise;
                    });
            }
        }
    }
});

