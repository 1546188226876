

app.factory('Excel', function ($window) {
    var uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function (s) {
            return $window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            })
        };
    return {
        tableToExcel: function (tableId, worksheetName) {
            var table = $(tableId),
                ctx = {worksheet: worksheetName, table: table.html()},
                href = uri + base64(format(template, ctx));
            return href;
        }
    };
});


app.factory('myHttpInterceptor', ['$q', function ($q) {
    var numRequests = 0;
    var ajaxSpinner = $("#spinner");
    var hide = function (r) {
        if (!--numRequests) {
            ajaxSpinner.hide();
        }
        return r;
    };
    return {
        'request': function(config) {
            numRequests++;
            ajaxSpinner.show();
            return config;
        },
        'response': function(response) {
            if (response && response.data && response.data.Error &&
                response.data.Error === true && response.data.Message) {
                return $q.reject(hide(response));
            }
            if (response && response.data && response.data.Error === false &&
                response.data.Message) {
            }
            return hide(response);
        },
        'responseError': function(response) {
            if (!response)
                return $q.reject(hide(response));
            if (response.data && response.data.Error &&
                response.data.Error === true && response.data.Message) {
            }
            return $q.reject(hide(response));
        }
    };
}]);


